<template>
  <div class="record">
    <div class="record_list_box">
      <div class="record_list">
        <div class="record_item_box" v-for="(item, index) in recorderList" :key="index">
          <span class="name">
            {{item.editor}}
            {{item.editTime}}
          </span>
          <div style="text-align: center;">
            《{{item.fileName}}》
          </div>
          <!-- @timeupdate="updateTime(index)"  播放时实时监听 -->
          <audio :src="item.fileUrl" ref="audioList" preload="metadata" @play="audioPlay(index)" controls></audio>
          <div v-if="userName === 'zhouyichang'" @click="deleteAudio(item)">删除此条</div>
          <!-- <div v-show="item.paused" @click="audioPlay(index)"><i class="iconfont iconbofang1"></i></div>
          <div v-show="!item.paused" @click="audioStop(index)"><i class="iconfont iconzantingtingzhi1"></i></div> -->
        </div>
      </div>
      <div v-show="showTimer" class="timer_box">
        <div class="time_box">
          {{mm | addZero}}:{{ss | addZero}}
        </div>
      </div>
    </div>
    <div class="operation_box">
      <div class="operation">
        <div class="item_box all_box">
          <div class="btn show_all" @click="showAll"><i class="iconfont iconliebiao"></i></div>
        </div>
        <div class="item_box btn_box">
          <div class="btn" v-show="audioState === 'inactive'" @click="record"><i class="iconfont iconluyin"></i></div>
          <div class="btn" v-show="audioState === 'recording'" @click="pauseRecord"><i
              class="iconfont iconzantingtingzhi"></i></div>
          <div class="btn" v-show="audioState === 'paused'" @click="resume"><i class="iconfont iconluyin"></i></div>
        </div>
        <div class="item_box stop_box">
          <div class="btn stop" v-show="audioState !== 'inactive'" @click="stopRecord"><i
              class="iconfont iconjieshu-"></i></div>
        </div>
      </div>
      <audio v-show="audioUrl" id="currentAudio" ref="currentAudio" :src="audioUrl" width="400" height="600"
        preload="metadata" controls></audio>
    </div>
    <pop ref="uploadBox" :forInput=true :fileName.sync="fileName" @sure="uploadRecorder" @cancel="cancel" />
    <pop ref="registerBox" :title="finalMes" sure-text="去登录" cancel-text="取消" @sure="registerSure"
      @cancel="registerCancel" />
  </div>
</template>

<script>
  import { uploadRec } from '@/vue_api/upload.js'
  import { addRecorder, allRecorder, delRecorder } from '@/vue_api/recorder.js'
  // import {addRecorder} from '@/vue_api/recorder.js'
  import pop from '@/component/pop/pop.vue'
  export default {
    name: 'recorder',
    components: {
      pop
    },
    data() {
      return {
        audioStream: {},
        audioUrl: '',
        audioState: '',
        fileName: '',
        fileBlob: {},
        recorderList: [],
        finalMes: '需要登录才能留声哦~',
        mm: 0,
        ss: 0,
        timer: 0,
        showTimer: 0
      }
    },
    filters: {
      addZero(val) {
        return val < 10 ? '0'+val : val
      }
    },
    mounted() {
      console.log('recorder.vue----', this.userName)
      this.createMedia()
      this.getAllRecorder()
    },
    methods: {
      // 控制计时器
      setTimer(m, s) {
        this.mm = m ? parseInt(m) : 0
        this.ss = s ? parseInt(s) : 0
        this.timer = setInterval(() => {
          if(this.ss < 59) {
            this.ss++
          } else {
            this.mm ++
            this.ss = 0
          }
        }, 1000);
      },
      audioPlay(index) {
        this.$refs.audioList.map((item, aindex) => {
          // item.pause()
          if(aindex == index) {
            item.play()
          } else {
            item.pause()
          }
          return item
        });
      },
      registerSure() {
        this.$refs.registerBox.close()
        this.$router.push({
          path: '/addUser'
        })
      },
      registerCancel() {
        this.$refs.registerBox.close()
      },
      // 删除指定录音
      deleteAudio(item) {
        delRecorder({ id: item.id, fileName: item.fileName }).then(res => {
          if (res && res.data && res.data.status) {
            this.getAllRecorder()
          }
        })
      },
      // // audioList的播放
      // audioPlay(index) {
      //   this.$refs.audioList[index].play()
      //   console.log(this.$refs.audioList[index].paused)
      //   this.recorderList[index].paused = this.$refs.audioList[index].paused
      //   console.log(this.$refs.audioList[index].paused, this.recorderList[index].paused)
      // },
      // // audioList的暂停
      // audioStop(index) {
      //   this.$refs.audioList[index].pause()
      //   this.recorderList[index].paused = this.$refs.audioList[index].paused
      //   console.log(this.$refs.audioList[index].paused)
      // },
      // // 播放时间改变时触发的事件
      // updateTime(index) {
      //   // console.log('正在播放----', this.$refs.audioList[index])
      //   console.log('正在播放----', index)
      // },
      // 获取全部录音
      getAllRecorder() {
        allRecorder().then(res => {
          console.log('全部录音----', res)
          this.recorderList = res && res.data && res.data.recorderList && res.data.recorderList.length !== 0 ? res.data.recorderList.map((item) => {
            item.paused = true
            return item
          }) : []
        })
      },
      // 显示全部录音
      showAll() {
        console.log('显示全部录音----');
      },
      // 获取时间
      formatDateTime(date) {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let second = date.getSeconds();
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
      },
      // 弹框确定，上传录音获得链接
      async uploadRecorder() {
        if (!this.userName) {
          this.$refs.registerBox.open()
          return
        }
        let formData = new FormData();
        formData.append('file', this.fileBlob, this.fileName)
        console.log('即将上传录音====', formData, this.fileBlob, this.fileName)
        await uploadRec(formData).then(res => {
          console.log('上传录音完成====', res.data)
          if (res && res.data && res.data.status) {
            let params = {
              editor: '',
              editTime: '',
              fileUrl: '',
              fileName: '',
              fileType: 0
            }
            this.$nextTick(() => {
              params.fileUrl = res.data.url
              params.fileName = res.data.name
              params.editor = this.userName
              params.editTime = this.formatDateTime(new Date())
              addRecorder(params).then(result => {
                console.log('成功了吗----', result)
                this.getAllRecorder()
              })
            })
          } else {
            console.log('上传失败')
          }
        }).catch(err => {
          console.log('上传失败了====', err)
        })
        this.$refs.uploadBox.close()
        this.mm = 0
        this.ss = 0
      },
      cancel() {
        this.$refs.uploadBox.close()
        this.mm = 0
        this.ss = 0
      },
      // 创建媒体流
      createMedia() {
        const _this = this
        const constraints = { audio: true, video: false }
        console.log('navigator有没有-----', navigator, navigator.mediaDevices)
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
              /* 使用这个stream stream */
              console.log('流媒体相关参数------', stream)
              _this.audioStream = new MediaRecorder(stream, {
                audioBitsPerSecond: 128000,
                // audioBitsPerSecond: 128000,
                MimeType: 'audio/webm'
              })
              _this.audioState = _this.audioStream.state
              console.log('流媒体当前状态-----000', _this.audioStream.state)

            })
            .catch(function (err) {
              /* 处理error */
              _this.finalMes = '录音失败' + err.toString()
              _this.$refs.goDateBox.open()
              console.log('录音失败-----', err)
            });
        } else {
          console.log('您的设备不支持录音~')
        }
      },
      // 暂停录音
      pauseRecord() {
        this.$refs.audioList && this.$refs.audioList.map(item => {
          item.pause()
          return item
        });
        clearInterval(this.timer)
        this.audioStream.pause()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----222', this.audioStream.state)
      },
      // 继续录音
      resume() {
        this.$refs.audioList && this.$refs.audioList.map(item => {
          item.pause()
          return item
        }); 
        this.setTimer(this.mm, this.ss)
        this.audioStream.resume()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----333', this.audioStream.state)
      },
      // 结束录音
      stopRecord() {
        this.$refs.audioList && this.$refs.audioList.map(item => {
          item.pause()
          return item
        }); 
        this.fileBlob = {}
        clearInterval(this.timer)
        this.showTimer = 0
        this.audioStream.stop()
        this.$nextTick(() => {
          this.fileName = this.userName + ' ' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' + new Date().getHours() + '点' + new Date().getMinutes() + '分'
        })
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----444', this.audioStream.state, this.fileName)
        this.audioStream.ondataavailable = (event) => {
          this.$nextTick(() => {
            this.audioUrl = URL.createObjectURL(event.data);
          })
          console.log('event----', event)
          if (event.data.size) {
            this.fileBlob = event.data
            this.$refs.uploadBox.open()
          }
        }
      },
      // 点我录音
      record() {
        this.$refs.audioList && this.$refs.audioList.map(item => {
          item.pause()
          return item
        });  
        if (!this.userName) {
          this.$refs.registerBox.open()
          return
        }
        this.audioUrl = ''
        this.showTimer = 1
        this.setTimer(this.mm, this.ss)
        this.audioStream.start()
        this.audioState = this.audioStream.state
        console.log('流媒体当前状态-----111', this.audioStream.state)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>